@import 'rx-shared-lib/dist/theme/font.css';

body {
  margin: 0;
  font-family: nunito-regular, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100vh;
  width: 100vw;
}

body .ant-modal-footer {
  border-top: 0px;
}

body .ant-select-item-group {
  font-weight: bold;
}

body .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  background-color: #f4f4f4;
}

body .ant-list-item {
  margin: 0px;
  padding: 0px;
}
