.patientCardDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.patientDetailBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 2px;
  padding-top: 124px;
}

.cardHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  position: absolute;
  top: 0px;
  right: 0px;
  background: white;
  z-index: 10;
  width: 100%;
}

.patientCardSubHeadings {
  font-size: 20px;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

.patientCardTableContent {
  font-weight: 400;
}

.patientCardTableContent2 {
  font-weight: 400;
  color: #000000 !important;
  font-size: 14px;
  line-height: 19.6px;
  text-transform: capitalize;
}

.cardHead .toggleBtn {
  background-color: #336d9f;
  width: 32%;
  box-sizing: border-box;

  border: none;
  border-radius: 2px;
  height: 90%;
  font-size: 16px;
  font-weight: 400;
  padding: 39.3px, 4px, 39.3px, 4px;
  color: #ffffff !important;
}

.cardHead .toggleBtn2 {
  background-color: #ffffff;
  box-sizing: border-box;
  width: 32%;
  height: 90%;
  border: 0.1px solid #868886;
  border-radius: 2px;
  font-size: 16px;
  padding: 39.3px, 4px, 39.3px, 4px;
  color: #868886 !important;
}

.toggleBtn2:hover {
  background-color: #f4f4f4;
}

.cardHead .btnDiv {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 14px;
  position: relative;
}

.patientDetailBox2 {
  margin-top: 5px;
  height: 100%;
}

.patientDetailBox2 .table {
  color: #181818;
  border-collapse: separate;
  border-spacing: 16px 4px;
  width: 100%;
  text-align: left;
  font-weight: 400;
}

.patientDetailBox2 td {
  padding-left: 2px;
  width: 50%;
  height: 36px;
}

.patientDetailBox2 .tableContentDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 30px;
  font-size: 16px;
  text-align: left;
}

.tableContentDiv table td {
  padding-left: 0px;
}

.detailContentDiv table td {
  border-bottom: 1px solid #DEDEDE;
  border-spacing: 16px 0;
  vertical-align: middle;
}

.patientDetailBox2 .tableDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
:first-of-type(.patientDetailBox2 .tableDiv) {
  margin-top: 16px;
}

.patientDetailBox2 .detailContentDiv {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.patientDetailBox2 .editBtns {
  padding: 5px;
  width: 48%;
  background-color: white;
  color: #5e615e;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
}

.patientDetailBox2 .saveBtn {
  padding: 5px;
  cursor: pointer;
  width: 48%;
  background-color: #c75833;
  border: 0.2px solid #c75833;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
  font-weight: 600;
}

.patientDetailBox2 Input {
  font-size: 16px;
  color: #181818;
  width: 100%;
  background-color: #ffffff;
  /* border: none; */
  border-color: #545454;
  text-decoration: none;
  outline-color: none;
}

.editTable tr td {
  border: 1px solid #545454;
  width: 50%;
  border-radius: 4px;
  color: #181818;
  margin: 0;
}

.infoIconDiv {
  margin-left: 5px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 24px;
  border-radius: 50%;
  margin-bottom: 2px;
}

.infoCircle {
  font-size: 18px;
  color: rgb(216, 216, 216);
}

.closeBtn :hover {
  color: orange;
  transition: 0.1s;
}

.stepMeasure {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
}

.stepsDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 80px;
}

.stepsDiv2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.stepTablediv {
  width: 100%;
  margin-top: 5px;
  font-size: 14px;
  line-height: 30px;
  text-align: justify;
  background: white;
}

.stepTablediv td {
  width: 50%;
}

.stepTable {
  width: 100%;
  font-weight: 600 !important;
  background:  #EDECFE;
  padding: 16px;
}
.stepTable table td {
  padding-bottom: 8px;
}

.stepTablediv table {
  width: 100%;
  background: #f2f5f9;
  padding-left: 4px;
  padding-right: 4px;
  color: #363936;
  font-weight: 400;
}

.circular {
  height: 32px;
  width: 32px;
  border: 1px solid #363936;
  border-radius: 50%;
  display: flex;
  font-size: 16px;
  color: #363936;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 600px;
}

.taskDiv {
  width: '100%';
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  box-shadow: rgb(0 0 0 / 35%) 0px 1px 8px;
  margin-top: 2px;
  height: 48px;
}

.taskDiv:hover {
  background: #f4f4f4;
}

.taskDiv:active {
  background: #f4f4f4;
}

.taskdivshow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  background: white;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 35%) 0px 1px 8px;
  margin-top: 2px;
}

.taskDiv2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #9a9c9a;
  border-radius: 4px;
  padding: 3px;
  height: 40px;
}

.taskDiv3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.taskStatusPass {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 41%;
  height: 27px;
  color: #23a942 !important;
  padding-left: 10px;
  padding-right: 10px;
  border: 0.9px solid #23a942;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600px;
}

.taskStatusFail {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 41%;
  height: 27px;
  color: #dc2626 !important;
  padding-left: 10px;
  padding-right: 10px;
  border: 0.9px solid #dc2626;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600px;
}

.datepicker {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  margin-top: 5px;
}

.medicationTable {
  font-weight: 600;
  color: #363936 !important;
  width: 100%;
  font-size: 16px;
  border: none;
  line-height: 31px;
  margin-bottom: 5px;
}

.TaskDropDown {
  background-color: rgb(255, 255, 255) !important;
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TaskDropDown :active {
  background-color: #f4f4f4;
}

.dropDownArrow {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 36px;
  height: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownArrowHover {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 36px;
  height: 40px;
  border: 1px solid #9a9c9a;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
}
.editStyles {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-items: center;
  border: none !important;
}
.editStyles Input {
  width: 77%;
}
.patientCardTableContentHead2 {
  font-weight: 600px !important;
  color: #363936 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #b7421b !important;
}
