.matchedText {
  font-weight: bold;
  background-color: rgba(0, 0, 0, 0);
  margin: 0px;
  padding: 0px;
}

.header {
  font-size: 32px;
  color: #004987;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding-top: 20px;
}

.modalIcon {
  width: 60px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  margin: 32px;
}
