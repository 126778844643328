.rowHeaderTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.paginationBar {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}

.gridMain{
  color:red !important;
}
