.darkText {
  font-size: 16px;
  font-weight: 600;
}

.mediumText {
  font-size: 16px;
  font-weight: 400;
}

.dataCell {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  padding-right: 17px;
  padding-left: 17px;
}
.dataCellUserRole {
  text-transform: capitalize;
}
.dobCell {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  padding-left: 17px;
}
