.commingSoonText {
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  color: #abd8df6b;
}

.drawerTitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column {
  background: white !important;
}

.carePlans {
  display: flex;
  flex-direction: column;
}

.workItems {
  display: flex;
  flex-direction: column;
  color: #336d9f;
}

.assignee {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}

.gridRoot {
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: calc(100vh - 150px);
 padding-left: 16px;
 padding-right: 16px;
  overflow: auto; 
}

.gridRootUsers {
  height: calc(100vh - 80px);
}

.title {
  font-weight: bold;
}
.searchIcon {
  display: flex;
  padding-left: 15px;
}
.textAlignCenter {
  text-align-last: center;
}
.activeTab {
  border-bottom: 3px solid #f15623;
  padding-bottom: 19px;
}
.navBarTab {
  width: 114px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #363936;
  display: inline-block;
  cursor: pointer;
}
.navbarTextStyle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #363936;
  margin-right: 16px;
}
.displayOptions {
  right: 24px;
  text-align-last: right;
}
.navbarIcons {
  top: 3px;
  position: relative;
  padding-left: 15px;
}
.iconWithText {
  cursor: pointer;
  margin-left: 16px;
  display: flex;
  justify-content: flex-end;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: red;
}

.optionBox {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 0.5px solid #bcbec0;
  cursor: pointer;
}

.displayHeader {
  color: rgb(154, 156, 154);
  display: flex;
  align-content: center;
  align-items: baseline;
  justify-content: center;
  padding-top: 10px;
}
.loader {
  display: flex;
  justify-content: center;
}

.fieldLabel {
  color: #231f20;
  font-weight: 500;
}

.saveFilterTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  flex: 1;
}

.Highlight {
  background-color: #ffd54f;
}

.Active {
  background-color: #f48f42;
}

.showDeleteFilterButton {
  color: gray;
}

.hideDeleteFilterButton {
  display: none !important;
}

.buttonFont {
  font-weight: bold !important;
}

.maxWidth {
  max-width: 284px !important;
}

.topHeader {
  display: flex;
  align-items: center;
  padding: 15px;
}

.passStatus {
  display: flex !important;
  align-items: center;
  justify-content: left;
  width: 95px;
  height: 30px;
  color: #23a942 !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600px;
}

.failStatus {
  display: flex !important;
  align-items: center;
  justify-content: left;
  width: 95px;
  height: 30px;
  color: #dc2626 !important;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600px;
}
.urgentDot {
  border: 1px solid #DC2626;
  background-color: #DC2626 !important;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  padding: 2px;
  margin-right: 10px;
}

.noDot {
  height: 10px;
  width: 10px;
  padding: 2px;
  margin-right: 10px;
}
