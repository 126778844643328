.img {
  position: absolute;
  height: 80px;
  width: 80px !important;
  left: 73.5px;
  top: 40px;
  border-radius: 0px;
  text-align: center;
}

.topHeader {
  color: #ffffff;
  background: #e5ecf3;
  display: flex;
  row-gap: 0px;
  justify-content: space-between;
}

.headerName {
  left: 24px;
}

.hsLogo {
  left: 24px;
}

.profile {
  right: 24px;
  display: flex !important;
  flex-direction: row-reverse !important;
}

.greeting {
  font-style: normal;
  font-size: 72px;
  font-weight: 300;
  line-height: 82px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
}

.card {
  width: 227px;
  height: 220px;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.15);
  border-radius: 19.2462px !important;
}

.card:hover {
  background: #f4f4f4 !important;
}

.buttonFont {
  font-weight: bold !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}
