.loginRoot {
  background-color: #e5ecf3;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-image: url("../../../src/assets/Bkgd_1.svg");
  background-position: 40%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 1024px) {
  .loginRoot {
    background-image: url("../../../src/assets/Bkgd_2.svg");
  }
}

.loginInputBox {
  border: 1px solid #ffffff;
  border-radius: 20px;
  display: flex;
  flex: 0.5;
  justify-content: center;
  flex-direction: column;
  padding: 5px 5px 0px 5px;
  align-self: end;
  width: 100%;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.loginInputFields {
  color: black;
  border: 1px solid;
  border-radius: 5px;
}

.formBox {
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  width: 480px;
  align-self: end;
  margin-right: 15%;
}

.titleBar {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}


.forgotPasswordTitle {
  width: 480px;
  height: 39px;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #004987;
}