.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.ant-btn-icon-only {
  border: none !important;
}

.ant-form-item-control-input .ant-btn-icon-only {
  color: #f4784f !important;
  visibility: hidden;
}

.ant-form-item-control-input:hover .ant-btn-icon-only {
  color: #f4784f !important;
  visibility: visible;
}

.ant-btn-primary {
  background: #c75833;
  border: none;
}

.ant-btn-primary:hover {
  background: #b7421b;
  border: none;
}

.ant-btn-primary:active {
  background: #cf7152;
  border: none;
}

.ant-btn-primary:focus {
  background: #b7421b;
  border: none;
}

.ag-theme-alpine .ag-root-wrapper {
  border: none;
}

.ag-theme-alpine .ag-header {
  background: #f2f2f2;
}

.ag-theme-alpine .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
  align-items: flex-start !important;
}

.ant-radio-wrapper {
  display: flex !important;
}

.ant-select-selection {
  background-color: green !important;
}
.ant-select-item-option-content .patientTag {
  display: none;
}

.ant-select-item ant-select-item-option ant-select-item-option-selected {
  display: flex;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fef5e9 !important;
}

.ant-select-item-option-content .navBar {
  padding: 0px 10px;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin-left: -15px !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
  display: flex !important;
}

.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #336d9f;
}

#measureDropDown {
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    visibility: hidden;
    display: none;
  }
}

// Custom Scrollbar Harmony Style
body {
  overflow: overlay;
}

*::-webkit-scrollbar {
  display: block;
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-track-piece {
  background-color: #00000000;
}

*::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: rgba(54, 57, 54, 0.2);
  background-clip: padding-box;
  border: 1px solid #f4f4f4;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  width: 2px;
  background-color: rgba(54, 57, 54, 0.4);
  background-clip: padding-box;
  border: 1px solid rgba(54, 57, 54, 0.4);
  border-radius: 8px;
}

.rc-virtual-list-scrollbar-thumb {
  width: 2px;
  background-color: rgba(54, 57, 54, 0.2) !important;
  background-clip: padding-box !important;
  border: 1px solid #f4f4f4 !important;
  border-radius: 8px !important;
}

.rc-virtual-list-scrollbar-thumb:hover {
  width: 2px;
  background-color: rgba(54, 57, 54, 0.4) !important;
  background-clip: padding-box !important;
  border: 1px solid rgba(54, 57, 54, 0.4) !important;
  border-radius: 8px !important;
}

// To remove the controls on the Input
/* Chrome, Safari, Edge, Opera */
#cutpnt-input::-webkit-outer-spin-button,
#cutpnt-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#cutpnt-input[type='number'] {
  -moz-appearance: textfield;
}

.datasource-popover .ant-popover-inner-content{
  padding: 0px;
}

.datasource-popover .ant-popover-inner{
  filter: drop-shadow(0px 9px 18px rgba(0, 0, 0, 0.15));
}

// global datasource popover of user list screen css
.datasource-popover .ant-popover-arrow {
  width: 0;
  height: 0;
  right: 118px;
  position: absolute;
  z-index: 1;
}
.datasource-popover.ant-popover-placement-top .ant-popover-arrow {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid white;
  right: 118px;
  top: 97%;
}

.datasource-popover.ant-popover-placement-bottom .ant-popover-arrow {
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid white;
  top: -8%;
}

.datasource-popover .ant-popover-content{
  position: relative;
}

.datasource-popover .ant-popover-arrow-content{
  display: none;
}
// global notification css
.notification-alert.ant-notification-notice {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
  padding-right: 32px;
  padding-bottom: 20px;
}

.notification-alert .ant-notification-notice-icon {
  margin-left: unset;
}

.notification-alert .ant-notification-notice-with-icon .ant-notification-notice-message,
.notification-alert .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px;
}

.notification-alert .ant-notification-notice-close-x {
  width: 16px;
  height: 16px;
  display: flex;
}
.notification-alert .ant-notification-notice-close {
  top: 8px;
  right: 8px;
}

//user list grid
.ag-body-viewport.ag-layout-normal{
  overflow-y: overlay;
}

.ag-theme-alpine .ag-ltr .ag-cell{
  text-transform: capitalize;
}

.add-user-datasource .ant-form-item{
  margin-bottom: 2px;
}